import React from "react";
import { Collapse, Col } from "antd";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../CSS/record.css";
import AvatarList from "../../Conversation/Desktop/AvatarList";
import { publiclistAvatar } from "../../api/accountAction";
import preloader from "../../../assets/loader/pre_loader.gif";

const Privatepodlist = () => {
  const dispatch = useDispatch();
  const [podlist, setPodList] = useState([]);
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  // const [records, setRecords] = useState([]);
  // const [notpinnedavatar, setNotPinnedAvatar] = useState([]);
  useEffect(() => {
    const input = {
      event_type: "public_avatar_details",
    };
    setPreloaderStatus(true);
    dispatch(publiclistAvatar(input))
      .unwrap()
      .then(({ data }) => {
        if (JSON.parse(data.aivolvex_control_plane).length > 0) {
          var all_pod_list = JSON.parse(data.aivolvex_control_plane).map(
            (v) => v.pod
          );
          const pod = new Set([...all_pod_list]);
          // setRecords(JSON.parse(data.aivolvex_control_plane));
          const item_list = [...pod].map((pod_name, index) => {
            return {
              key: index,
              label: pod_name,
              children: (
                <AvatarList
                  avatarlist={JSON.parse(data.aivolvex_control_plane).filter(
                    (v, index) => v.pod === pod_name
                  )}
                />
              ),
            };
          });
          setPreloaderStatus(false);
          setPodList(item_list);
          // setNotPinnedAvatar(item_list);
        } else {
          setPodList([]);
          // setRecords([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const items = podlist;
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader h-18">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <div className="dashboard-board-private">
          <Collapse items={items} expandIconPosition="right" />
        </div>
      )}
    </>
  );
};
export default memo(Privatepodlist);
