import { Col, Row, Tabs } from "antd";
import Handoff from "./Handoff";
import Details from "./Details";
import EmailContent from "./EmailContent";
import { useDispatch, useSelector } from "react-redux";

const Historymodal = (props) => {
  const { avatardetails } = useSelector((state) => state.chat);
  const items = [
    {
      key: "1",
      label: avatardetails.chat_type === "voice" ? "Transcript" : "History",
      children: <Handoff record={props.records} />,
    },
    {
      key: "2",
      label: "Details",
      children: (
        <Details records={props.records} avatardetails={avatardetails} />
      ),
    },
    {
      key: "3",
      label: "Email Content",
      children: <EmailContent records={props.records} />,
    },
  ];
  return (
    <>
      <Row className="record-history-tab chatbot-dashboard-main-body">
        <Col span={24}>
          <Tabs
            className="record-tab"
            defaultActiveKey="1"
            items={items}
            // centered
          />
        </Col>
      </Row>
    </>
  );
};
export default Historymodal;
