import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  ApolloProvider,
  gql,
  concat,
} from "@apollo/client";

const httpLink = new HttpLink({
  uri: "https://wtbkv7pwh5hdrkhlhs3ljthnv4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "x-api-key": "da2-fb3jh5ax3zd6tlyh6g7f6n4s5i",
    },
  }));

  return forward(operation);
});

const without_client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
});

export default without_client;
