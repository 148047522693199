import { Col, Table, Tag, Space, Modal, Tooltip, Popconfirm } from "antd";
import { memo, useEffect, useState } from "react";
import Historymodal from "./Historymodal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { historyrecordlist } from "../../api/accountAction";
import { capitalizeWords } from "../../customhook/CustomHoom";
import { Icon } from "@iconify/react";
import MarkdownToJSX from "markdown-to-jsx";
import PopConfirmQa from "./PopConfirmQa";

const Recordtablemain = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [tablerecord, setTablerecord] = useState([]);
  const [historyopen, setHistoryOpen] = useState(false);
  const [records, setRecords] = useState([]);
  const [voicestatus, setVoiceStatus] = useState(false);
  const { avatardetails } = useSelector((state) => state.chat);

  const [tablecolumns, setTableColums] = useState([
    {
      title: "Date&Time",
      dataIndex: "datetime",
      key: "datetime",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Convo Type",
      dataIndex: "typeofconversation",
      key: "typeofconversation",
      align: "center",

      render: (_, { typeofconversation }) => (
        <>
          {typeofconversation != null && (
            <>
              {typeofconversation.split(",").map((tag) => {
                let color;
                if (tag.trim() === "voice") {
                  color = "purple";
                } else if (tag.trim() === "chat") {
                  color = "blue";
                } else {
                  color = "red";
                }
                return (
                  <Tag color={color} key={tag.trim()}>
                    {tag.trim()}
                  </Tag>
                );
              })}
            </>
          )}
        </>
      ),
      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      render: (sentiment, record) => {
        let iconColor, iconName, textColor;
        switch (sentiment) {
          case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "Negative":
            iconColor = "#FF2441";
            iconName = "ion:sad";
            textColor = "#FF2441";
            break;
          default:
            iconColor = "black";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.sentiment_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {sentiment && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(sentiment)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      render: (summary, record) => {
        const truncatedSummary =
          summary?.length > 4 ? summary.slice(0, 5) + "..." : summary;
        return (
          <>
            {summary && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="fluent:notepad-edit-20-regular"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>{" "}
                  <span>{truncatedSummary}</span>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Opportunity",
      dataIndex: "lead",
      key: "lead",
      align: "center",
      render: (lead, record) => {
        let iconColor, iconName, textColor;

        switch (lead) {
          case "Yes":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "No":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.lead_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {lead === "Yes" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="green">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
                {lead === "No" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="red">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },

      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Action",
      dataIndex: "action_to_be_taken",
      key: "action_to_be_taken",
      render: (summary, record) => {
        return (
          <>
            {summary != null && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="eos-icons:role-binding-outlined"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Handedoff",
      dataIndex: "handed_off",
      key: "handed_off",
      render: (handed_off, record) => {
        let iconColor, iconName, textColor;
        switch (handed_off) {
          case "Negative":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;

          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.handed_off_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {handed_off && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>{" "}
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(handed_off)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: " ",
      key: "action",
      render: (text, record, index) => (
        <Space size="middle">
          <a
            className="view-more"
            onClick={() => handlehistory(record)}
            style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
          >
            View more
          </a>
        </Space>
      ),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  ]);
  const [voicetablecolumns, setVoiceTableColums] = useState([
    {
      title: "Date&Time",
      dataIndex: "datetime",
      key: "datetime",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(text)}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => capitalizeWords(text),
    },
    {
      title: "Convo Type",
      dataIndex: "typeofconversation",
      key: "typeofconversation",
      align: "center",

      render: (_, { typeofconversation }) => (
        <>
          {typeofconversation != null && (
            <>
              {typeofconversation.split(",").map((tag) => {
                let color;
                if (tag.trim() === "voice") {
                  color = "purple";
                } else if (tag.trim() === "chat") {
                  color = "blue";
                } else {
                  color = "red";
                }
                return (
                  <Tag color={color} key={tag.trim()}>
                    {tag.trim()}
                  </Tag>
                );
              })}
            </>
          )}
        </>
      ),
      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      render: (sentiment, record) => {
        let iconColor, iconName, textColor;
        switch (sentiment) {
          case "Positive":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "Negative":
            iconColor = "#FF2441";
            iconName = "ion:sad";
            textColor = "#FF2441";
            break;
          default:
            iconColor = "black";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.sentiment_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {sentiment && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(sentiment)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: "Summary",
      dataIndex: "summary",
      key: "summary",
      render: (summary, record) => {
        const truncatedSummary =
          summary?.length > 4 ? summary.slice(0, 5) + "..." : summary;
        return (
          <>
            {summary && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="fluent:notepad-edit-20-regular"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>{" "}
                  <span>{truncatedSummary}</span>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Opportunity",
      dataIndex: "lead",
      key: "lead",
      align: "center",
      render: (lead, record) => {
        let iconColor, iconName, textColor;

        switch (lead) {
          case "Yes":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;
          case "Neutral":
            iconColor = "#FFC400";
            iconName = "ph:smiley-meh-fill";
            textColor = "#FFC400";
            break;
          case "No":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<MarkdownToJSX>
                  {record.lead_explanation}
                </MarkdownToJSX>
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {lead === "Yes" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="green">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
                {lead === "No" && (
                  <>
                    <span>
                      {" "}
                      <Tag color="red">{capitalizeWords(lead)}</Tag>
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },

      onHeaderCell: () => ({
        style: {
          whiteSpace: "normal",
          wordWrap: "break-word",
          textAlign: "center",
        },
      }),
    },
    {
      title: "Action",
      dataIndex: "action_to_be_taken",
      key: "action_to_be_taken",
      render: (summary, record) => {
        return (
          <>
            {summary != null && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Popconfirm
                  className="record-dashboard-popup"
                  placement="top"
                  title=""
                  description=<MarkdownToJSX>{summary}</MarkdownToJSX>
                  footer={false}
                  okText=""
                  cancelText=""
                  cancelButtonProps={{ style: { display: "none" } }}
                  okButtonProps={{ style: { display: "none" } }}
                >
                  <span>
                    <Icon
                      icon="eos-icons:role-binding-outlined"
                      style={{ color: "#fff", fontSize: "24px" }}
                      className="cursor"
                    />
                  </span>
                </Popconfirm>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "QA Check",
      dataIndex: "qa_status",
      key: "qa_status",
      render: (handed_off, record) => {
        let iconColor, iconName, textColor;
        switch (handed_off) {
          case "Incomplete":
            iconColor = "red";
            iconName = "ion:sad";
            textColor = "red";
            break;
          case "Completed":
            iconColor = "#0BB852";
            iconName = "mdi:smiley";
            textColor = "#0BB852";
            break;

          default:
            iconColor = "#fff";
            iconName = "ph:smiley-fill";
        }

        return (
          <>
            <div style={{ whiteSpace: "nowrap" }}>
              {/* {console.log(record)} */}
              <Popconfirm
                className="record-dashboard-popup"
                placement="top"
                title=""
                description=<PopConfirmQa content={record.qa_content} />
                footer={false}
                okText=""
                cancelText=""
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
              >
                {handed_off && (
                  <>
                    <span>
                      <Icon
                        icon={iconName}
                        style={{ color: iconColor, fontSize: "24px" }}
                        className="cursor"
                      />
                    </span>{" "}
                    <span
                      style={{ color: textColor }}
                      className="smiley-text cursor"
                    >
                      {capitalizeWords(handed_off)}
                    </span>
                  </>
                )}
              </Popconfirm>
            </div>
          </>
        );
      },
    },
    {
      title: " ",
      key: "action",
      render: (text, record, index) => (
        <Space size="middle">
          <a
            className="view-more"
            onClick={() => handlehistory(record)}
            style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
          >
            View more
          </a>
        </Space>
      ),
      onHeaderCell: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  ]);
  const handlehistory = (record) => {
    setRecords(record);
    setHistoryOpen(true);
  };
  const handleCancel = () => {
    setHistoryOpen(false);
  };
  const formatDate = (inputDate) => {
    if (inputDate) {
      const date = new Date(inputDate);
      const month = date.toLocaleString("default", { month: "short" });
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedDate = `${day}-${month}- ${year}, -${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amOrPm}`;
      return formattedDate;
    }
  };

  const columns = tablecolumns;
  useEffect(() => {
    const input = {
      event_type: "public_agent_history",
      id: id,
    };
    dispatch(historyrecordlist(input))
      .unwrap()
      .then(({ data }) => {
        const value = JSON.parse(data.aivolvex_control_plane);
        const table = value.map((datas) => {
          const raw_text = JSON.parse(datas.qa_content);
          return {
            service: datas.service,
            category: datas.category,
            language: datas.language,
            name: datas.user_name,
            email: datas.email,
            handed_off: datas.handed_off,
            typeofconversation: datas.type_of_conversation,
            handed_off_explanation: datas.handed_off_explanation,
            phone: datas.mobile,
            datetime: datas.date_time,
            sentiment: datas.sentiment,
            lead_explanation: datas.lead_explanation,
            sentiment_explanation: datas.sentiment_explanation,
            conversation_id: datas.conversation_id,
            summary: datas.summary,
            lead: datas.lead,
            action_to_be_taken: datas.action_to_be_taken,
            email_content: datas.email_content,
            qa_status: datas.qa_status,
            qa_content: datas.qa_content,
          };
        });

        setTablerecord(table);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  // const dataSource = [
  //     {
  //         key: '1',
  //         name: 'Mike',
  //         lead: 'No',
  //         summary: 'negative',
  //         sentiment: 'positive',
  //         typeofconversation: 'chat',
  //         email: 'abc@gmail.com',
  //         datetime: '27-Nov-2023, 2:19PM',
  //         handsoff: 'yes',
  //     },
  //     {
  //         key: '2',
  //         name: 'Mike',
  //         lead: 'yes',
  //         summary: '',
  //         sentiment: 'negative',
  //         typeofconversation: 'chat',
  //         email: 'abc@gmail.com',
  //         datetime: '27-Nov-2023, 2:19PM',
  //         handsoff: 'no',
  //     },
  //     {
  //         key: '3',
  //         name: 'Mike',
  //         lead: 'No',
  //         summary: 'neutral',
  //         sentiment: 'neutral',
  //         typeofconversation: 'chat',
  //         email: 'abc@gmail.com',
  //         datetime: '27-Nov-2023, 2:19PM',
  //         handsoff: 'yes',
  //     },

  // ];
  return (
    <>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        className="dashboard-board-background-table"
      >
        <Table
          dataSource={tablerecord}
          // dataSource={dataSource}
          columns={
            avatardetails.chat_type == "voice"
              ? voicetablecolumns
              : tablecolumns
          }
          scroll={{ x: 1500 }}
          className="record-dashboard-table"
          // className="mt-3 ms-2 costimize-bootstrap-table"
        />
      </Col>
      <Modal
        open={historyopen}
        footer={false}
        onCancel={handleCancel}
        className="report-table-modal"
        width={1000}
      >
        <Historymodal records={records} />
      </Modal>
    </>
  );
};

export default Recordtablemain;
