import { Col, Row, Tabs } from "antd";
import Recordtablemain from "./Recordtablemain";
import "../CSS/record.css";
import Chatbotdashboardmain from "./Chatbotdashboardmain";
import { avatarDetails } from "../../Slice/reducer/chatReducer";
import { getAvatarDetails } from "../../api/accountAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import preloader from "../../../assets/loader/pre_loader.gif";

const Recordmainbody = () => {
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  const [avatardetails, setAvatarDetails] = useState([]);
  const dispatch = useDispatch();
  const { pinnedstatus, chatmessage } = useSelector((state) => state.chat);
  const { id, conversationId } = useParams();
  const [preloaderstatus, setPreloaderStatus] = useState(false);
  useEffect(() => {
    const input = {
      id: id,
      user_id: userName,
    };
    setPreloaderStatus(true);
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
        dispatch(avatarDetails(JSON.parse(data.aivolvex_control_plane)[0]));
        setPreloaderStatus(false);
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, pinnedstatus]);

  const items = [
    {
      key: "1",
      label: "Records",
      children: <Recordtablemain />,
    },
    // {
    //     key: '2',
    //     label: 'Dashboard',
    //     children: <Chatbotdashboardmain />
    // },
  ];
  return (
    <>
      {preloaderstatus ? (
        <>
          <Col span={24}>
            <div className="aivolvex-sub-loader fh-100">
              <img src={preloader} width="20%" />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <Row className="volvex-chat-header">
                <Col span={24}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                      <div>
                        <img
                          src={avatardetails.avatar_image}
                          alt="avatar-img"
                          className="volvex-chat-header-avatar-logo"
                        />
                      </div>
                      <div className="volvex-chat-avatar-title">
                        <label className="aivolvex-font">
                          {avatardetails.avatar_name}
                        </label>
                        {/* <div>
                    <Icon
                      onClick={() => {
                        setPinnedAvatar();
                      }}
                      icon="fluent-mdl2:pinned"
                      className={
                        avatardetails.pinned_status === "pinned"
                          ? "volvex-chat-header-icon cursor-pointer pinned-color"
                          : "volvex-chat-header-icon cursor-pointer"
                      }
                    />
                  </div> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="chatdashboard-record-dection chatbot-dashboard-main-body">
            <Col span={24}>
              <Tabs className="record-tab" defaultActiveKey="1" items={items} />
            </Col>
          </div>
        </>
      )}
    </>
  );
};
export default Recordmainbody;
