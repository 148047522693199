import { memo, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import aivolvex from "../../../assets/ui/X Icon SVG.svg";
import { getAvatarDetails } from "../../api/accountAction";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';

const AvatarList = (props) => {
  return (
    <>
      <div className="">
        <Row gutter={[0, 16]}>
          {props.avatarlist.map((data) => {
            return <AvatarDetails records={data} />;
          })}
        </Row>
      </div>
    </>
  );
};

const AvatarDetails = (props) => {
  const navigateFn = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const onClickHandler = () => {
    const currentPath = location.pathname;
    // if (currentPath === '/public-agent') {
    if (currentPath.includes('/public-agent')) {
      navigateFn(`/public-agent/${props.records.id}`);
    } else {
      navigateFn(`/conversation/${props.records.id}`);
    }
  }

  const dispatch = useDispatch();

  const [avatardetails, setAvatarDetails] = useState([]);
  const userName = atob(
    localStorage.getItem("AIVolveXIdentityServiceProvider.auth")
  );
  useEffect(() => {
    const id = props.records.id;
    const input = {
      id: props.records.id,
      // id: props.records.avatar_id,
      user_id: userName,
    };
    dispatch(getAvatarDetails(input))
      .unwrap()
      .then(({ data }) => {
        // console.log(data)
        setAvatarDetails(JSON.parse(data.aivolvex_control_plane)[0]);
      })
      .catch((err) => {
        console.log(err);
        // message.error(err);
      });
  }, [props]);
 
  return (
    <>
      <Col
        span={24}
        className="cursor-pointer"
        // onClick={() => {
        //   navigateFn(`/conversation/${props.records.id}`);
        // }}
        onClick={onClickHandler}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            <div>
              {/* <Icon
          icon="icon-park-outline:add"
          className="volvex-avatar-list-new"
        />{" "} */}
              {avatardetails.avatar_image && (
                <img
                  className="volvex-sidemenu-list-avatar-logo"
                  src={avatardetails.avatar_image}
                />
              )}

            </div>
            <div className="volvex-sidemenu-list-avatar-name aivolvex-font">
              {avatardetails.avatar_name}
            </div>
          </div>
          <div className="volvex-sidemenu-list-token-body d-flex justify-content-end">
            {/* <div className="volvex-sidemenu-list-token-value aivolvex-font">
              20
            </div>
            <div className="volvex-sidemenu-list-token-logo">
              <img src={aivolvex} alt="logo" className="token-footer-logo" />
            </div> */}
          </div>
        </div>
      </Col>
    </>
  );
};

export default memo(AvatarList);
